import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_6/slide1';
import SubSlider from './wrapperSubSlider';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-6-1"}}) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_6/mobile/4-6-1-bg.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    audioImageA: file(relativePath: { eq: "chapter_3/sub_1/3-1-3-audio__A.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
  }
  `);
  return (
    <Template query={query} subSlider={<SubSlider />} title="Szia VI" />
  );
};


export default Slide;
